"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createStyleTag = exports.attrToObj = void 0;
function parseValue(value) {
    if (value === '' || value === 'true') {
        return true;
    }
    if (value === 'false') {
        return false;
    }
    if (Number(value).toString() === value) {
        return Number(value);
    }
    return value;
}
function parseKey(key) {
    const parts = key.split('-');
    if (parts[0] === 'data') {
        parts.splice(0, 1);
    }
    const newParts = [parts[0]];
    for (let i = 1; i < parts.length; i++) {
        const firstLetter = parts[i].slice(0, 1);
        const restOfLetters = parts[i].slice(1);
        const newPart = firstLetter.toUpperCase() + restOfLetters;
        newParts.push(newPart);
    }
    return newParts.join('');
}
const attrToObj = (attrs) => {
    const attrsObj = {};
    const length = attrs.length;
    for (let i = 0; i < length; i++) {
        const { name, value } = attrs[i];
        attrsObj[parseKey(name)] = parseValue(value);
    }
    return attrsObj;
};
exports.attrToObj = attrToObj;
const createStyleTag = (css) => {
    var _a;
    const linkElement = document.createElement('style');
    linkElement.innerHTML = css;
    (_a = document.querySelector('head')) === null || _a === void 0 ? void 0 : _a.appendChild(linkElement);
};
exports.createStyleTag = createStyleTag;
