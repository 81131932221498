import { useTheme } from '@mui/material/styles'
import { Button, Dialog, DialogTitle, DialogContent, IconButton, Typography, useMediaQuery } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { StudentsList } from '../parts/StudentsList'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useSetFormData } from '../../hooks/useSetFormData'

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}))

export const UpdateChildNamesDialog = ({ values, open = false, setOpen, config }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const setFormData = useSetFormData()

  const handleSubmit = async (values) => {
    setFormData(values)
    setOpen(false)
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="child-name-update-dialog"
    >
      <DialogTitle id="child-name-update-dialog">
        <StyledCloseButton aria-label="close" onClick={() => setOpen(false)}>
          <CloseIcon />
        </StyledCloseButton>
      </DialogTitle>
      <DialogContent sx={{ padding: 5 }}>
        Please enter the names of your children below <br />
        <br />
        <StudentsList values={values} config={config} canEditChildName={true} />
        <Button
          variant="contained"
          type="button"
          size="large"
          aria-label="open breakdown"
          sx={{ marginTop: 2, marginBottom: 2 }}
          onClick={() => handleSubmit(values)}
          endIcon={<ArrowForwardIcon />}
        >
          UPDATE DETAILS
        </Button>
        <Typography variant={'caption'} component={'p'} sx={{ color: 'custom.textLight' }}>
          All information provided is collected and stored according to our{' '}
          <a target={'blank'} href={config.details.privacyUrl}>
            privacy policy
          </a>
          .
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
