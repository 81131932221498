"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PoweredBy = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styles_1 = require("@mui/material/styles");
const url_1 = require("../../../shared/constants/url");
const StyledPoweredBy = (0, styles_1.styled)('div')(({ theme }) => ({
    span: {
        color: theme.palette.custom.textLight,
        fontSize: '15px',
    },
    '& a': {
        color: '#07769B',
    },
    img: {
        height: '16px!important',
        width: 'auto',
        marginRight: '8px',
        verticalAlign: 'middle',
        display: 'inline',
    },
}));
const PoweredBy = ({ hideLogo }) => {
    return ((0, jsx_runtime_1.jsxs)(StyledPoweredBy, { children: [hideLogo !== true && ((0, jsx_runtime_1.jsx)("img", { src: "https://files.feesable.io/logo_feesable_small.png", height: "16", alt: "Feesable Logo" })), (0, jsx_runtime_1.jsx)(material_1.Typography, { component: 'span', children: (0, jsx_runtime_1.jsxs)("em", { children: ["Powered by", ' ', (0, jsx_runtime_1.jsx)("a", { href: `${url_1.FEESABLE_URL}?v=${process.env.REACT_APP_UI_VERSION}`, target: "_blank", rel: "noreferrer", children: "Feesable" })] }) })] }));
};
exports.PoweredBy = PoweredBy;
