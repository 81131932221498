import PropTypes from 'prop-types'
import { useConfig } from '../../hooks/useConfig'
import { Typography, Box, Button } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { StudentsList } from '../parts/StudentsList'

export const FormStep3 = ({ values, addAnotherChild, editChild }) => {
  const config = useConfig()

  const handleAddAnotherChild = () => {
    addAnotherChild(values)
  }

  return (
    <>
      <Typography variant={'h5'}>Summary</Typography>
      <StudentsList values={values} editChild={editChild} config={config} />
      <br />
      <br />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          type="button"
          onClick={() => handleAddAnotherChild()}
          size="large"
          disableElevation={true}
          endIcon={<PersonAddIcon />}
          sx={{
            '&:hover': {
              color: 'primary.main',
            },
          }}
        >
          Add another {config.terms.child}
        </Button>
        {values.students && values.students.length > 0 && (
          <Button
            sx={{ marginLeft: 2 }}
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            disableElevation={true}
            endIcon={<ArrowForwardIcon />}
          >
            NEXT
          </Button>
        )}
      </Box>
    </>
  )
}

FormStep3.propTypes = {
  values: PropTypes.object,
  addAnotherChild: PropTypes.func,
  editChild: PropTypes.func,
}
