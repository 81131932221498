import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import WarningIcon from '@mui/icons-material/Warning'
import Typography from '@mui/material/Typography'
import { InfoPopup } from '../parts/InfoPopup'

const StyledEstimateCallout = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontSize: '1.15rem',
  color: theme.palette.custom.textLight,
  backgroundColor: theme.palette.custom.primaryLight,
  borderStyle: 'none',
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  borderTopColor: theme.palette.custom.surfaceDark,
  '&:first-of-type': {
    borderTop: 'none',
  },
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  marginTop: 0,
  marginBottom: 0,
  fontStyle: 'italic',
  '& svg': {
    color: theme.palette.custom.primary,
    marginRight: '15px',
    marginTop: '-3px',
    marginBottom: '-4px',
  },

  [theme.breakpoints.down('xs')]: {
    fontSize: '1rem',
  },
}))

const getIcon = (type) => {
  const icons = {
    warning: <WarningIcon fontSize={'large'} />,
  }
  return icons[type] || <h4>Invalid Icon</h4>
}

const EstimateCallout = ({ text, info, icon }) => {
  return (
    <StyledEstimateCallout>
      {getIcon(icon)}
      {text}
      {info && <InfoPopup popId={info.replace(/[^a-zA-Z0-9]/g, '')} infos={[info]} />}
    </StyledEstimateCallout>
  )
}

EstimateCallout.propTypes = {
  text: PropTypes.string,
  info: PropTypes.string,
  icon: PropTypes.string,
}

export { EstimateCallout }
