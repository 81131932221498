"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetConfig = void 0;
const react_1 = require("react");
const immer_1 = require("immer");
const store_1 = require("../store");
const formatHelper_1 = require("../store/formatHelper");
const useSetConfig = () => {
    const setState = (0, store_1.useSetState)();
    const setConfig = (0, react_1.useCallback)((data) => {
        setState((obj) => (0, immer_1.produce)(obj, (draft) => {
            draft.config = data.options
                ? Object.assign(Object.assign({}, data), { lookup: (0, formatHelper_1.convertSitesToObject)(data.options.sites) }) : data;
        }));
    }, [setState]);
    return setConfig;
};
exports.useSetConfig = useSetConfig;
