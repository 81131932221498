import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Box, TextField, IconButton, Typography } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useActiveStudentIndex } from '../../hooks/useActiveStudentIndex'
import { IconGraduate } from './IconGraduate'

const StyledListItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.custom.surfaceMedium,
  borderWidth: '1px',
  borderColor: theme.palette.custom.surfaceDark,
  borderStyle: 'solid',
  borderRadius: '4px',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  marginTop: theme.spacing(1),

  '& .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  '&.StyledListItem-active': {
    borderWidth: '1px 1px 1px 10px',
    borderColor: theme.palette.custom.primary,
    background: '#ffffff',
    paddingLeft: '14px',
  },

  '&.StylesListItem-new': {
    backgroundColor: '#ffffff',
    borderColor: theme.palette.custom.surfaceDark,
    color: theme.palette.custom.surfaceDark,
  },
}))

const StudentsList = ({ values, editChild, config, canEditChildName }) => {
  const activeStudentIndex = useActiveStudentIndex()
  const { setTouched, touched } = useFormikContext()
  const students = values.students

  const handleEditChild = async (index, action) => {
    const currentStudent = students[index]
    if (!currentStudent) return

    await setTouched({
      ...touched,
      students: values.students.map(() => ({
        name: true,
        grade: true,
        startYear: true,
        current: true,
        variant: true,
      })),
    })
    if (currentStudent && currentStudent.current !== null && currentStudent.grade && currentStudent.startYear)
      editChild(index, action, values)
  }

  if (!config.lookup) return null

  return students?.map((student, index) => {
    const site = config.lookup[student.site]
    const studentName = student.name || `${config.terms.child} ${index + 1}`
    const studentField = !canEditChildName ? (
      studentName
    ) : (
      <Field name={`students.${index}.name`} as={TextField} placeholder={studentName} variant="standard" />
    )

    const itemStates = {
      editing: index === activeStudentIndex,
      new: !(student.current || student.site || student.grade || student.name),
    }

    return (
      <StyledListItem
        key={index}
        className={`
              ${itemStates.editing && 'StyledListItem-active'}
              ${itemStates.new && 'StylesListItem-new'}
            `}
      >
        <Box>
          <IconGraduate />
          &nbsp;&nbsp;&nbsp;
          <Typography variant={'body2'}>
            {studentField}
            {site && !itemStates.new && (
              <>
                {' '}
                -{' '}
                <strong>
                  {site.gradesObj[student.grade] && site.gradesObj[student.grade].label + ', '}
                  {student.startYear ? student.startYear : ''}
                  {student.site === 'default' ? '' : `, ${site.label}`}
                </strong>
                <span style={{ fontSize: '0.8em' }}>
                  {' '}
                  {student.current === true && '(current student)'}
                  {student.current === false && '(new student)'}
                </span>
              </>
            )}
          </Typography>
        </Box>
        {!itemStates.new && editChild && (
          <Box>
            <IconButton aria-label="edit" size={'small'} color="primary" onClick={() => handleEditChild(index, 'edit')}>
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              size={'small'}
              color="primary"
              onClick={() => handleEditChild(index, 'delete')}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        )}
      </StyledListItem>
    )
  })
}

StudentsList.propTypes = {
  values: PropTypes.object.isRequired,
  editChild: PropTypes.func,
  config: PropTypes.object.isRequired,
  canEditChildName: PropTypes.bool,
}

export { StudentsList }
