import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { formatNumberCurrency } from '../../services/TextHelper'
import { InfoPopup } from '../parts/InfoPopup'

const StyledChildRow = styled(Box)(({ theme }) => ({
  '& p': {
    textAlign: 'left',
  },
  '&.childRowLine': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  '& .childRowLineTitle': {
    color: theme.palette.custom.textLight,
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  '& .childRowLineValue': {
    color: theme.palette.custom.textMedium,
    fontSize: '13px',
  },
  '&.childRowTotal': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  '& .childRowTotalTitle': {
    color: theme.palette.custom.textMedium,
    fontSize: '13px',
  },
  '& .childRowTotalValue': {
    color: theme.palette.custom.textMedium,
    fontSize: '13px',
    fontWeight: 'bold',
  },
  '& .colLeft': {
    width: '100%',
  },
  '& .colRight1': {
    color: theme.palette.custom.textLight,
  },
  '& .colRight2': {
    width: '112px',
    textAlign: 'right',
  },
  '&>.colRight2>p': {
    textAlign: 'right!important',
  },
  '& .refs': {
    fontSize: '0.8em',
    paddingLeft: '5px',
    marginTop: '-10px',
  },
}))

const EstimateBreakdownChild = ({ student, periods, config }) => {
  if (!student.site) return null

  return (
    <Box sx={{ borderBottom: '1px solid #dcdcdc' }}>
      <Box>
        <Typography variant={'subtitle2'} sx={{ color: 'custom.textMedium', paddingTop: 2, marginBottom: 1 }}>
          {student.heading} {student.newStudent && '(new student)'}
        </Typography>
      </Box>
      {student.lines.map((line) => {
        const info = (line.infos || []).length > 0 ? <InfoPopup popId={line.key} infos={line.infos} /> : null

        return (
          <StyledChildRow key={line.key} className={`childRow${line.type}`}>
            <div className="colLeft">
              <Typography variant={'body2'} className={`childRow${line.type}Title`}>
                {line.title}
                {line.refs && line.refs.length > 0 && <sup className="refs">[{line.refs.join(', ')}]</sup>}
                {info}
              </Typography>
            </div>
            {line.type === 'Total' && (
              <>
                {periods.nonYear && (
                  <div className="colRight1">
                    <Typography variant={'body2'} className={`childRow${line.type}Value`}>
                      <strong>{formatNumberCurrency(student.periods[periods.nonYear.key], null, config.terms)}</strong>
                    </Typography>
                  </div>
                )}
                <div className="colRight2">
                  <Typography variant={'body2'} className={`childRow${line.type}Value`}>
                    <strong>{formatNumberCurrency(student.periods[periods.year.key], null, config.terms)}</strong>
                  </Typography>
                </div>
              </>
            )}
            {line.type !== 'Total' && (
              <Typography variant={'body2'} className={`childRow${line.type}Value`}>
                {formatNumberCurrency(line.amt, 0, config.terms)}
              </Typography>
            )}
          </StyledChildRow>
        )
      })}
    </Box>
  )
}

EstimateBreakdownChild.propTypes = {
  student: PropTypes.object,
  periods: PropTypes.object,
}

export { EstimateBreakdownChild, StyledChildRow }
