"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentPage = exports.useSetCurrentPage = void 0;
const react_1 = require("react");
const immer_1 = require("immer");
const store_1 = require("../store");
const store_2 = require("../store");
const useSetCurrentPage = () => {
    const setState = (0, store_1.useSetState)();
    const setCurrentPage = (0, react_1.useCallback)((currentPage) => {
        setState((obj) => (0, immer_1.produce)(obj, (draft) => {
            draft.currentPage = (0, immer_1.castDraft)(currentPage);
        }));
    }, [setState]);
    return setCurrentPage;
};
exports.useSetCurrentPage = useSetCurrentPage;
const useCurrentPage = () => {
    const state = (0, store_2.useTrackedState)();
    return state.currentPage;
};
exports.useCurrentPage = useCurrentPage;
