import { useCallback } from 'react'
import { produce } from 'immer'
import { useSetState } from '../store'

export const useSetPeriods = () => {
  const setState = useSetState()
  const setPeriods = useCallback(
    (data) => {
      setState((obj) =>
        produce(obj, (draft) => {
          draft.periods = data
        })
      )
    },
    [setState]
  )
  return setPeriods
}

export const getPeriodSettings = (primaryKey, config) => {
  const ret = {}
  const periods = config.setup.periods.available.reduce((acc, period) => {
    acc[period.key] = period
    return acc
  }, {})

  ret.primary = periods[primaryKey]

  if (!ret.primary) throw new Error(`Invalid Period Key '${primaryKey}' provided`)

  const secondaryKey =
    config.setup.periods.primary === primaryKey ? config.setup.periods.secondary : config.setup.periods.primary

  ret.secondary = periods[secondaryKey]

  // output year so the breakdown main column is always perYear
  ret.year = periods['perYear']

  ret.nonYear = primaryKey === 'perYear' ? ret.secondary : ret.primary

  return ret
}
