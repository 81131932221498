"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatNumberReset = exports.formatNumberCurrency = void 0;
/**
 * Format the number as currency
 * @param {float/int} number the number to format
 * @param {int} roundTo number of decimal places to round (def null=don't round)
 * @param {currencySymbol} symbol
 * @param {string} decimal Text to use as decimal separator (if not '.' then thousands separator becomes '.')
 * @param {boolean} isPost Should the Symbol be after the number?
 */
const formatNumberCurrency = (number = 0, roundTo = undefined, terms = {
    currencySymbol: '$',
    currencyDecimal: '.',
    currencyDisplayPost: false,
}) => {
    let str = '0';
    if (roundTo !== undefined) {
        str = number.toFixed(roundTo);
    }
    else {
        str = number.toString();
    }
    const symbol = (terms === null || terms === void 0 ? void 0 : terms.currencySymbol) || '$';
    const decimal = terms.currencyDecimal || '.';
    const isPost = terms.currencyDisplayPost || false;
    const separator = decimal === '.' ? ',' : '.';
    // let str = numberString ? numberString.toString() : '0'
    const parts = str.split('.');
    parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${separator}`);
    str = parts.join(decimal);
    return isPost ? `${str} ${symbol}` : `${symbol}${str}`;
};
exports.formatNumberCurrency = formatNumberCurrency;
const formatNumberReset = (string) => {
    return parseFloat(string.replace(/,/g, ''));
};
exports.formatNumberReset = formatNumberReset;
