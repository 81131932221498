"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toErrorWithMessage = void 0;
const isErrorWithMessage = (error) => {
    return (typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        typeof error.message === 'string');
};
const toErrorWithMessage = (error) => {
    if (isErrorWithMessage(error))
        return error;
    try {
        return new Error(JSON.stringify(error));
    }
    catch (_a) {
        // if there's an error stringifying the error
        return new Error(String(error));
    }
};
exports.toErrorWithMessage = toErrorWithMessage;
