import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import CheckIcon from '@mui/icons-material/Check'
import { formatNumberCurrency } from '../../services/TextHelper'
import { useConfig } from '../../hooks/useConfig'
import { IconGraduate } from '../parts/IconGraduate'
import { Button } from '@mui/material'
import theme from '../../theme'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'
import { AdditionalFields } from '../parts/AdditionalFields'
import { useFormData } from '../../hooks/useFormData'

const StyledTag = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  backgroundColor: theme.palette.custom.primaryLight,
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(1),

  [theme.breakpoints.down('sm')]: {
    fontSize: '0.6rem',
  },
}))

const EstimateChild = ({ currentBreakdownStudent, showDivider, periods, isIndicative, setIsChildNameDialogOpen }) => {
  const config = useConfig()
  const [extrasOpen, setExtrasOpen] = useState(false)
  const [hasExtras, setHasExtras] = useState(false)
  const formData = useFormData()

  if (!currentBreakdownStudent.site) return null

  useEffect(() => {
    if (
      formData?.students.some((s) => {
        if (s.id !== currentBreakdownStudent.id) return false
        if (
          s.extras &&
          s.extras.some((e) => {
            if (e.year !== currentBreakdownStudent.startYear) return false
            if (e.selected && e.selected.length) return true
          })
        )
          return true
        return false
      })
    )
      setHasExtras(true)
  }, [currentBreakdownStudent])

  return (
    <>
      <Box sx={{ paddingTop: 1, paddingBottom: 1, color: 'custom.textLight' }}>
        <Grid container sx={{ paddingBottom: 1 }}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <IconGraduate />
                {!currentBreakdownStudent.name ? (
                  <Button onClick={() => setIsChildNameDialogOpen(true)}>{currentBreakdownStudent.name}</Button>
                ) : (
                  <Typography variant={'body2'} sx={{ marginLeft: 1 }}>
                    {currentBreakdownStudent.name}
                  </Typography>
                )}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  },
                }}
              >
                <StyledTag variant={'caption'}>
                  {config.lookup[currentBreakdownStudent.site].gradesObj[currentBreakdownStudent.grade].label}
                </StyledTag>
                {currentBreakdownStudent.newStudent && <StyledTag variant={'caption'}>New Student</StyledTag>}
                {currentBreakdownStudent.variant &&
                  config.lookup[currentBreakdownStudent.site].gradesObj[currentBreakdownStudent.grade].variants && (
                    <StyledTag variant={'caption'}>
                      {
                        config.lookup[currentBreakdownStudent.site].gradesObj[currentBreakdownStudent.grade]
                          .variantsObj[currentBreakdownStudent.variant].label
                      }
                    </StyledTag>
                  )}
                {currentBreakdownStudent.site !== 'default' && (
                  <StyledTag variant={'caption'}>{config.lookup[currentBreakdownStudent.site].label}</StyledTag>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              {config.extras && (
                <Box mt={'9px'}>
                  {hasExtras && (
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<AddToPhotosIcon />}
                      onClick={() => setExtrasOpen(true)}
                    >
                      Edit Extras
                    </Button>
                  )}
                  {!hasExtras && (
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<AddToPhotosIcon />}
                      onClick={() => setExtrasOpen(true)}
                    >
                      Add Extras
                    </Button>
                  )}
                  {extrasOpen && (
                    <AdditionalFields
                      extrasOpen={extrasOpen}
                      setExtrasOpen={setExtrasOpen}
                      currentBreakdownStudent={currentBreakdownStudent}
                      config={config}
                      formData={formData}
                    />
                  )}
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                marginBottom: '16px',
              }}
            >
              {isIndicative && <Typography sx={{ fontSize: '0.8rem', fontStyle: 'italic' }}>INDICATIVE</Typography>}
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '2.5rem',
                  lineHeight: 1.235,
                  letterSpacing: '0.00735em',
                }}
              >
                {formatNumberCurrency(currentBreakdownStudent.periods[periods.primary.key], 0, config.terms)}
              </Typography>
              <Typography variant={'caption'}>{periods.primary.title}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {currentBreakdownStudent.flags.hasDiscount && (
              <Box
                sx={{
                  color: 'custom.primary',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <CheckIcon />
                <Typography variant={'caption'}>
                  {currentBreakdownStudent.flags.hasDiscount ? 'Discounts Applied' : 'Discount Applied'}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              {periods.secondary && (
                <Typography sx={{ fontSize: '1rem', fontWeight: 400, lineHeight: 1.75 }}>
                  {formatNumberCurrency(currentBreakdownStudent.periods[periods.secondary.key], 0, config.terms)}{' '}
                  {periods.secondary.title}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>

        {showDivider && <Divider />}
      </Box>
    </>
  )
}

EstimateChild.propTypes = {
  currentBreakdownStudent: PropTypes.object,
  showDivider: PropTypes.bool,
  periods: PropTypes.object,
  isIndicative: PropTypes.bool,
  setIsChildNameDialogOpen: PropTypes.func,
}

export { EstimateChild }
