import { Box, Button, Grid, Skeleton, Typography } from '@mui/material'

const FeeEstimateSkeleton = () => {
  return (
    <Box>
      {/* Header Section */}
      <Box mb={3} height={300}>
        <Box p={3} bgcolor="primary.main" color="white" borderRadius="6px">
          {/* Title */}
          <Box>
            <Typography variant="h3">
              <Skeleton width="30%" />
            </Typography>
          </Box>
          {/* Fee Amount */}
          <Box mb={1} display="flex" alignItems="center" justifyContent="end">
            <Typography variant="h1">
              <Skeleton width={300} />
            </Typography>
          </Box>
          {/* Frequency Buttons */}
          <Grid mb={1} display="flex" alignItems="center" justifyContent="end" gap={2} flexWrap="wrap">
            {[...Array(4)].map((_, index) => (
              <Grid item key={index}>
                <Button variant="contained" disabled>
                  <Skeleton width={80} />
                </Button>
              </Grid>
            ))}
          </Grid>
          <Box display="flex" alignItems="end" justifyContent="end">
            <Typography variant="h6" width="35%">
              <Skeleton />
            </Typography>
          </Box>
        </Box>
      </Box>
      <Grid display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={2} flexWrap="wrap">
        {[...Array(2)].map((_, index) => (
          <Grid item key={index} mx={1} gap={2} width="100%">
            <Box display="flex" alignItems="center" p={3} border={1} borderColor="grey.300" borderRadius={4}>
              {/* Icon and Child Info */}
              <Box display="flex" flexDirection="column" width="60%" alignItems="start" justifyContent="start">
                <Box alignItems="center" justifyContent="start">
                  <Skeleton variant="circular" width={40} height={40} />
                </Box>
                <Box alignItems="center" justifyContent="start">
                  <Typography variant="h6">
                    <Skeleton width={80} />
                  </Typography>
                </Box>
              </Box>
              {/* Add Extras Button */}
              <Box mx={3}>
                <Skeleton width={120} height={80} />
              </Box>
              {/* Fee Details */}
              <Box display="flex" flexDirection="column" alignItems="end" justifyContent="end" width="40%">
                <Typography variant="h3" width="80%">
                  <Skeleton />
                </Typography>
                <Typography variant="body2" width="60%">
                  <Skeleton />
                </Typography>
                <Typography variant="body2" width="30%">
                  <Skeleton />
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export { FeeEstimateSkeleton }
