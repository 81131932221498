import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Collapse, Grid, Link, Typography } from '@mui/material'
import { DataBuilder } from '../../services/DataBuilderSvc'
import { useCalcResponse } from '../../hooks/useCalcResponse'
import { useConfig } from '../../hooks/useConfig'
import { useSetPeriods, getPeriodSettings } from '../../hooks/useSetPeriods'
import { useSetSidebar } from '../../hooks/useSetSidebar'
import { EstimateYear } from './EstimateYear'
import { UpdateChildNamesDialog } from './UpdateChildNamesDialog'
import { EstimateBreakdown } from './EstimateBreakdown'
import { usePeriods } from '../../hooks/usePeriods'
import { useFormData } from '../../hooks/useFormData'
import { styled } from '@mui/system'
import { FeeEstimateSkeleton } from './FeeEstimateSkeleton'

const Estimate = ({ values, handleEditChildren }) => {
  const calcResponse = useCalcResponse()
  const config = useConfig()
  const setPeriods = useSetPeriods()
  const [breakdownData, setBreakdownData] = useState({})
  const [collapseAlert, setCollapseAlert] = useState(false)
  const dataBuilder = new DataBuilder('app')
  const setSidebar = useSetSidebar()
  const [isChildNameDialogOpen, setIsChildNameDialogOpen] = useState(false)
  const { suppressActions } = useFormData()

  // breakdown modal TODO: put logic in a separate hook
  const [isBreakdownModalOpen, setIsBreakdownModalOpen] = useState(false)
  const [selectedYearIdx, setSelectedYearIdx] = useState(null)
  const viewBreakDownModal = async (yearIndex) => {
    await setSelectedYearIdx(yearIndex)
    setIsBreakdownModalOpen(true)
  }

  const periods = usePeriods()
  // breakdown modal
  useEffect(() => {
    if (calcResponse.data) setBreakdownData(dataBuilder.buildBreakdown(calcResponse.data, config))
  }, [calcResponse])

  useEffect(() => {
    if (!suppressActions) {
      const timer = setTimeout(() => {
        setCollapseAlert(true)
      }, 4000)
      return () => clearTimeout(timer)
    }
  }, [suppressActions])

  const configPeriods = config.setup.periods

  useEffect(() => {
    setPeriods(getPeriodSettings(configPeriods.primary, config))
  }, [configPeriods, setPeriods, config])

  useEffect(() => {
    if ((breakdownData || {}).flags) {
      setSidebar({
        show: true,
        display: ['cta', 'contact', 'notes'],
        showFutureYearDisclaimer: breakdownData.flags.hasIndicative,
      })
    }
  }, [breakdownData, setSidebar])

  const StyledAlert = styled(Alert)(() => ({
    marginBottom: 12,

    '& a': {
      textDecoration: 'underline',
    },
    '& h4': {
      fontSize: 'x-large',
      fontWeight: 'bold',
    },
    '& h6': {
      fontSize: 'large',
      fontWeight: 'bold',
    },
    '& .MuiAlert-icon': {
      paddingTop: 12,
      fontSize: 'xx-large',
    },
  }))

  if (!values || !breakdownData.years) return null

  const selectPeriodHandler = (primaryKey) => {
    const periods = getPeriodSettings(primaryKey, config)
    setPeriods(periods)
  }

  const hasChildrenWithoutName = values.students.reduce((acc, s) => {
    if (!s.name) acc = true
    return acc
  }, false)

  const showUpdateChildName =
    (
      (config.fields.student || {}).studentName || {
        validationFlow: false,
      }
    ).validationFlow && hasChildrenWithoutName

  let indicativeFlagCount = 0

  if (!calcResponse.data || calcResponse.isLoading) return <FeeEstimateSkeleton />

  return (
    <>
      <Grid item xs={12}>
        {config.extras && suppressActions && (
          <Alert severity="info" sx={{ marginBottom: 2 }}>
            {config.terms.extrasIntroDefault}
          </Alert>
        )}
        {config.extras && !suppressActions && (
          <StyledAlert severity="success">
            <Collapse in={!collapseAlert} timeout={1500}>
              <div dangerouslySetInnerHTML={{ __html: config.terms.extrasIntroQuotedLong }} />
            </Collapse>
            <Collapse in={collapseAlert} timeout={1500}>
              <div dangerouslySetInnerHTML={{ __html: config.terms.extrasIntroQuotedSummary }} />
              <Link onClick={() => setCollapseAlert(false)} color={'custom.textMedium'} sx={{ float: 'right' }}>
                see full message
              </Link>
            </Collapse>
          </StyledAlert>
        )}
        {!config.extras && (
          <Typography variant={'body1'} sx={{ paddingTop: 1, paddingBottom: 1 }}>
            Thanks
            {values.parent.firstName ? ` ${values.parent.firstName}, below` : '. Below'} is your fee estimate.{' '}
            {values.parent.email
              ? "We've sent you an email as a record of your fee estimate, plus other important enrolment information."
              : "If you would like this sent to you by email, please hit 'Add or Edit Children' and provide your email address."}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        {breakdownData.years.map((breakdownYearData, index) => {
          if (breakdownYearData.flags.indicative) indicativeFlagCount += 1
          return (
            <EstimateYear
              setIsChildNameDialogOpen={setIsChildNameDialogOpen}
              breakdownYearData={breakdownYearData}
              onViewBreakdown={() => viewBreakDownModal(index)}
              index={index}
              key={index}
              config={config}
              selectPeriodHandler={selectPeriodHandler}
              handleEditChildren={handleEditChildren}
              showUpdateChildName={showUpdateChildName}
              showIndicative={indicativeFlagCount === 1}
            />
          )
        })}
        <EstimateBreakdown
          yearData={breakdownData.years[selectedYearIdx]}
          open={isBreakdownModalOpen}
          onCloseBreakdown={() => setIsBreakdownModalOpen(false)}
          config={config}
          periods={periods}
          isNextEnabled={!!breakdownData.years[selectedYearIdx + 1]}
          isPrevEnabled={!!breakdownData.years[selectedYearIdx - 1]}
          onNext={() => setSelectedYearIdx(selectedYearIdx + 1)}
          onPrev={() => setSelectedYearIdx(selectedYearIdx - 1)}
        />
      </Grid>
      <UpdateChildNamesDialog
        values={values}
        open={isChildNameDialogOpen}
        setOpen={setIsChildNameDialogOpen}
        config={config}
      />
    </>
  )
}

Estimate.propTypes = {
  values: PropTypes.object,
  handleEditChildren: PropTypes.func,
}

export { Estimate }
