"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetSidebar = void 0;
const react_1 = require("react");
const immer_1 = require("immer");
const store_1 = require("../store");
const useSetSidebar = () => {
    const setState = (0, store_1.useSetState)();
    const setSidebar = (0, react_1.useCallback)((sidebar) => {
        setState((obj) => (0, immer_1.produce)(obj, (draft) => {
            draft.sidebar = Object.assign(Object.assign({}, draft.sidebar), sidebar);
        }));
    }, [setState]);
    return setSidebar;
};
exports.useSetSidebar = useSetSidebar;
