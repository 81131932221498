const convertSitesToObject = (sites = []) => {
  return sites.reduce((acc, site) => {
    acc[site.id] = {
      ...site,
      gradesObj: site.grades.reduce((acc2, grade) => {
        acc2[grade.grade] = !grade.variants
          ? grade
          : {
              ...grade,
              variantsObj: grade.variants.reduce((acc3, variant) => {
                acc3[variant.variant] = variant
                return acc3
              }, {}),
            }
        return acc2
      }, {}),
    }
    return acc
  }, {})
}

export { convertSitesToObject }
