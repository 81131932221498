"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockInput = exports.mockResponse = void 0;
const currentYear = new Date().getFullYear();
exports.mockResponse = {
    years: [
        {
            year: 2024,
            increaseApplied: false,
            family: {
                fees: {
                    lineItems: [
                        {
                            ruleId: 'eb5e67c3',
                            title: 'Annual Maintenance Levy',
                            type: 'fee',
                            freq: 1,
                            unit: 500,
                            amt: 500,
                            increaseApplied: false,
                            info: 'Annual fee per family, refundable',
                        },
                    ],
                    notes: [
                        {
                            ruleId: '466486e1',
                            title: 'Building Fund (per family, tax-deductible). Parents and Guardians are encouraged to contribute $250 per year to the Building Fund, which is tax deductible. Families are welcome to contribute more than the suggested amount.',
                            type: 'note',
                            freq: 1,
                            unit: 250,
                            amt: 250,
                            increaseApplied: false,
                            params: {
                                excludeFromTotal: true,
                            },
                        },
                    ],
                    subTotals: {
                        discount: 0,
                        fee: 500,
                        tuition: 0,
                        extra: 0,
                        excluded: 0,
                        total: 500,
                    },
                    totals: {
                        discount: -3675,
                        fee: 1870,
                        tuition: 15300,
                        extra: 0,
                        excluded: 0,
                        total: 13495,
                    },
                    periods: {
                        perTerm: 3374,
                        perYear: 13495,
                    },
                    breakdown: [],
                },
            },
            students: [
                {
                    id: '91a4bb1a-9937-44be-a9b5-ba88dd486a38',
                    name: 'Child 1',
                    startYear: 2024,
                    current: true,
                    newStudent: false,
                    increaseApplied: false,
                    grade: '9',
                    gradeLabel: 'Year 9',
                    variant: '',
                    startKey: 2016,
                    fees: {
                        lineItems: [
                            {
                                ruleId: '791fggh4',
                                title: 'Tuition Fee',
                                type: 'tuition',
                                freq: 1,
                                unit: 7950,
                                amt: 7950,
                                increaseApplied: false,
                                site: 'default',
                            },
                            {
                                ruleId: 'fcd205467',
                                title: 'Tuition Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 310,
                                amt: 310,
                                increaseApplied: false,
                            },
                            {
                                ruleId: 'fcd2045467',
                                title: 'IT Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 430,
                                amt: 430,
                                increaseApplied: false,
                                info: 'Annual levy to cover the cost of technology supplies and devices.',
                            },
                        ],
                        notes: [],
                        subTotals: {
                            discount: 0,
                            fee: 740,
                            tuition: 7950,
                            extra: 0,
                            excluded: 0,
                            total: 8690,
                        },
                        periods: {
                            perTerm: 2173,
                            perYear: 8690,
                        },
                        breakdown: [],
                    },
                    ruleLog: [],
                    site: 'default',
                },
                {
                    id: '274b301c-3aee-497c-9241-3689e0246d52',
                    name: 'Jeremy',
                    startYear: 2024,
                    current: false,
                    newStudent: true,
                    increaseApplied: false,
                    grade: '7',
                    gradeLabel: 'Year 7',
                    variant: '',
                    startKey: 2018,
                    fees: {
                        lineItems: [
                            {
                                ruleId: '791fggh4',
                                title: 'Tuition Fee',
                                type: 'tuition',
                                freq: 1,
                                unit: 7350,
                                amt: 7350,
                                increaseApplied: false,
                                site: 'default',
                            },
                            {
                                ruleId: 'fcd205467',
                                title: 'Tuition Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 310,
                                amt: 310,
                                increaseApplied: false,
                            },
                            {
                                ruleId: 'fcd2045783234',
                                title: 'IT Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 320,
                                amt: 320,
                                increaseApplied: false,
                                info: 'Annual levy to cover the cost of technology supplies and devices.',
                            },
                            {
                                ruleId: '7e84c82a',
                                title: 'Sibling 2 Discount - 50%',
                                type: 'discount',
                                freq: 1,
                                unit: -3675,
                                amt: -3675,
                                increaseApplied: false,
                                params: {
                                    applyIfSiblingsEqual: 2,
                                },
                                groups: ['siblings'],
                            },
                        ],
                        notes: [],
                        subTotals: {
                            discount: -3675,
                            fee: 630,
                            tuition: 7350,
                            extra: 0,
                            excluded: 0,
                            total: 4305,
                        },
                        periods: {
                            perTerm: 1076,
                            perYear: 4305,
                        },
                        breakdown: [],
                    },
                    ruleLog: [],
                    site: 'default',
                },
            ],
        },
        {
            year: 2025,
            increaseApplied: false,
            family: {
                fees: {
                    lineItems: [
                        {
                            ruleId: 'eb5e67c3',
                            title: 'Annual Maintenance Levy',
                            type: 'fee',
                            freq: 1,
                            unit: 500,
                            amt: 500,
                            increaseApplied: false,
                            info: 'Annual fee per family, refundable',
                        },
                    ],
                    notes: [
                        {
                            ruleId: '466486e1',
                            title: 'Building Fund (per family, tax-deductible). Parents and Guardians are encouraged to contribute $250 per year to the Building Fund, which is tax deductible. Families are welcome to contribute more than the suggested amount.',
                            type: 'note',
                            freq: 1,
                            unit: 250,
                            amt: 250,
                            increaseApplied: false,
                            params: {
                                excludeFromTotal: true,
                            },
                        },
                    ],
                    subTotals: {
                        discount: 0,
                        fee: 500,
                        tuition: 0,
                        extra: 0,
                        excluded: 0,
                        total: 500,
                    },
                    totals: {
                        discount: -3675,
                        fee: 1970,
                        tuition: 15300,
                        extra: 0,
                        excluded: 0,
                        total: 13595,
                    },
                    periods: {
                        perTerm: 3399,
                        perYear: 13595,
                    },
                    breakdown: [],
                },
            },
            students: [
                {
                    id: '91a4bb1a-9937-44be-a9b5-ba88dd486a38',
                    name: 'Child 1',
                    startYear: 2025,
                    current: true,
                    newStudent: false,
                    increaseApplied: false,
                    grade: '10',
                    gradeLabel: 'Year 10',
                    variant: null,
                    startKey: 2016,
                    fees: {
                        lineItems: [
                            {
                                ruleId: '791fggh4',
                                title: 'Tuition Fee',
                                type: 'tuition',
                                freq: 1,
                                unit: 7950,
                                amt: 7950,
                                increaseApplied: false,
                                site: 'default',
                            },
                            {
                                ruleId: 'fcd2047457',
                                title: 'Tuition Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 410,
                                amt: 410,
                                increaseApplied: false,
                            },
                            {
                                ruleId: 'fcd2045467',
                                title: 'IT Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 430,
                                amt: 430,
                                increaseApplied: false,
                                info: 'Annual levy to cover the cost of technology supplies and devices.',
                            },
                        ],
                        notes: [],
                        subTotals: {
                            discount: 0,
                            fee: 840,
                            tuition: 7950,
                            extra: 0,
                            excluded: 0,
                            total: 8790,
                        },
                        periods: {
                            perTerm: 2198,
                            perYear: 8790,
                        },
                        breakdown: [],
                    },
                    ruleLog: [],
                    site: 'default',
                },
                {
                    id: '274b301c-3aee-497c-9241-3689e0246d52',
                    name: 'Jeremy',
                    startYear: 2025,
                    current: false,
                    newStudent: false,
                    increaseApplied: false,
                    grade: '8',
                    gradeLabel: 'Year 8',
                    variant: null,
                    startKey: 2018,
                    fees: {
                        lineItems: [
                            {
                                ruleId: '791fggh4',
                                title: 'Tuition Fee',
                                type: 'tuition',
                                freq: 1,
                                unit: 7350,
                                amt: 7350,
                                increaseApplied: false,
                                site: 'default',
                            },
                            {
                                ruleId: 'fcd205467',
                                title: 'Tuition Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 310,
                                amt: 310,
                                increaseApplied: false,
                            },
                            {
                                ruleId: 'fcd2045783234',
                                title: 'IT Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 320,
                                amt: 320,
                                increaseApplied: false,
                                info: 'Annual levy to cover the cost of technology supplies and devices.',
                            },
                            {
                                ruleId: '7e84c82a',
                                title: 'Sibling 2 Discount - 50%',
                                type: 'discount',
                                freq: 1,
                                unit: -3675,
                                amt: -3675,
                                increaseApplied: false,
                                params: {
                                    applyIfSiblingsEqual: 2,
                                },
                                groups: ['siblings'],
                            },
                        ],
                        notes: [],
                        subTotals: {
                            discount: -3675,
                            fee: 630,
                            tuition: 7350,
                            extra: 0,
                            excluded: 0,
                            total: 4305,
                        },
                        periods: {
                            perTerm: 1076,
                            perYear: 4305,
                        },
                        breakdown: [],
                    },
                    ruleLog: [],
                    site: 'default',
                },
            ],
        },
        {
            year: 2026,
            increaseApplied: false,
            family: {
                fees: {
                    lineItems: [
                        {
                            ruleId: 'eb5e67c3',
                            title: 'Annual Maintenance Levy',
                            type: 'fee',
                            freq: 1,
                            unit: 500,
                            amt: 500,
                            increaseApplied: false,
                            info: 'Annual fee per family, refundable',
                        },
                    ],
                    notes: [
                        {
                            ruleId: '466486e1',
                            title: 'Building Fund (per family, tax-deductible). Parents and Guardians are encouraged to contribute $250 per year to the Building Fund, which is tax deductible. Families are welcome to contribute more than the suggested amount.',
                            type: 'note',
                            freq: 1,
                            unit: 250,
                            amt: 250,
                            increaseApplied: false,
                            params: {
                                excludeFromTotal: true,
                            },
                        },
                    ],
                    subTotals: {
                        discount: 0,
                        fee: 500,
                        tuition: 0,
                        extra: 0,
                        excluded: 0,
                        total: 500,
                    },
                    totals: {
                        discount: -9487.5,
                        fee: 2710,
                        tuition: 23450,
                        extra: 0,
                        excluded: 0,
                        total: 16672.5,
                    },
                    periods: {
                        perTerm: 4168,
                        perYear: 16673,
                    },
                    breakdown: [],
                },
            },
            students: [
                {
                    id: '91a4bb1a-9937-44be-a9b5-ba88dd486a38',
                    name: 'Child 1',
                    startYear: 2026,
                    current: true,
                    newStudent: false,
                    increaseApplied: false,
                    grade: '11',
                    gradeLabel: 'Year 11',
                    variant: null,
                    startKey: 2016,
                    fees: {
                        lineItems: [
                            {
                                ruleId: '791fggh4',
                                title: 'Tuition Fee',
                                type: 'tuition',
                                freq: 1,
                                unit: 8150,
                                amt: 8150,
                                increaseApplied: false,
                                site: 'default',
                            },
                            {
                                ruleId: 'fcd2047457',
                                title: 'Tuition Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 410,
                                amt: 410,
                                increaseApplied: false,
                            },
                            {
                                ruleId: 'fcd2045467',
                                title: 'IT Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 430,
                                amt: 430,
                                increaseApplied: false,
                                info: 'Annual levy to cover the cost of technology supplies and devices.',
                            },
                        ],
                        notes: [],
                        subTotals: {
                            discount: 0,
                            fee: 840,
                            tuition: 8150,
                            extra: 0,
                            excluded: 0,
                            total: 8990,
                        },
                        periods: {
                            perTerm: 2248,
                            perYear: 8990,
                        },
                        breakdown: [],
                    },
                    ruleLog: [],
                    site: 'default',
                },
                {
                    id: '274b301c-3aee-497c-9241-3689e0246d52',
                    name: 'Jeremy',
                    startYear: 2026,
                    current: false,
                    newStudent: false,
                    increaseApplied: false,
                    grade: '9',
                    gradeLabel: 'Year 9',
                    variant: null,
                    startKey: 2018,
                    fees: {
                        lineItems: [
                            {
                                ruleId: '791fggh4',
                                title: 'Tuition Fee',
                                type: 'tuition',
                                freq: 1,
                                unit: 7950,
                                amt: 7950,
                                increaseApplied: false,
                                site: 'default',
                            },
                            {
                                ruleId: 'fcd205467',
                                title: 'Tuition Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 310,
                                amt: 310,
                                increaseApplied: false,
                            },
                            {
                                ruleId: 'fcd2045467',
                                title: 'IT Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 430,
                                amt: 430,
                                increaseApplied: false,
                                info: 'Annual levy to cover the cost of technology supplies and devices.',
                            },
                            {
                                ruleId: '7e84c82a',
                                title: 'Sibling 2 Discount - 50%',
                                type: 'discount',
                                freq: 1,
                                unit: -3975,
                                amt: -3975,
                                increaseApplied: false,
                                params: {
                                    applyIfSiblingsEqual: 2,
                                },
                                groups: ['siblings'],
                            },
                        ],
                        notes: [],
                        subTotals: {
                            discount: -3975,
                            fee: 740,
                            tuition: 7950,
                            extra: 0,
                            excluded: 0,
                            total: 4715,
                        },
                        periods: {
                            perTerm: 1179,
                            perYear: 4715,
                        },
                        breakdown: [],
                    },
                    ruleLog: [],
                    site: 'default',
                },
                {
                    id: '5b9c6824-5031-4e83-9321-438837d68925',
                    name: 'Jenny',
                    startYear: 2026,
                    current: false,
                    newStudent: true,
                    increaseApplied: false,
                    grade: '7',
                    gradeLabel: 'Year 7',
                    variant: '',
                    startKey: 2020,
                    fees: {
                        lineItems: [
                            {
                                ruleId: '791fggh4',
                                title: 'Tuition Fee',
                                type: 'tuition',
                                freq: 1,
                                unit: 7350,
                                amt: 7350,
                                increaseApplied: false,
                                site: 'default',
                            },
                            {
                                ruleId: 'fcd205467',
                                title: 'Tuition Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 310,
                                amt: 310,
                                increaseApplied: false,
                            },
                            {
                                ruleId: 'fcd2045783234',
                                title: 'IT Levy',
                                type: 'fee',
                                freq: 1,
                                unit: 320,
                                amt: 320,
                                increaseApplied: false,
                                info: 'Annual levy to cover the cost of technology supplies and devices.',
                            },
                            {
                                ruleId: '203ad6df',
                                title: 'Sibling 3 Discount - 75%',
                                type: 'discount',
                                freq: 1,
                                unit: -5512.5,
                                amt: -5512.5,
                                increaseApplied: false,
                                params: {
                                    applyIfSiblingsEqual: 3,
                                },
                                groups: ['siblings'],
                            },
                        ],
                        notes: [],
                        subTotals: {
                            discount: -5512.5,
                            fee: 630,
                            tuition: 7350,
                            extra: 0,
                            excluded: 0,
                            total: 2467.5,
                        },
                        periods: {
                            perTerm: 617,
                            perYear: 2468,
                        },
                        breakdown: [],
                    },
                    ruleLog: [],
                    site: 'default',
                },
            ],
        },
    ],
    produced: '2024-09-13T22:31:20.414Z',
    missingYears: 0,
    submissionId: 'e815778f-1773-405b-9196-481c2258d643',
    configStamp: {
        fees: {
            id: 'db40c3ff-5436-4463-81e3-f813571b48a0',
            activeFrom: '2021-01-07',
            releasedOn: '2021-01-07T00:00:00.000Z',
        },
        config: {
            id: '957839a2-8845-49f1-baca-52d25da0fb26',
        },
    },
};
exports.mockInput = {
    uiVersion: '2.0.0',
    dataVersion: 1,
    schoolId: 'feesableCollegeB',
    parent: {
        firstName: 'Sample',
        lastName: 'Person',
        email: 'test@feesable.com.au',
        phone: '',
        postcode: '5555',
        contactMethod: 'email',
        emailParent: '',
        code: '12345',
        comment: 'No Comment',
    },
    family: { schoolCard: false, staffDiscount: false },
    students: [
        {
            id: '274b301c-3aee-497c-9241-3689e0246d52',
            name: 'Jeremy',
            grade: '7',
            startYear: currentYear,
            current: false,
            currentStudent: 'false',
            variant: '',
            site: 'default',
        },
        {
            id: '5b9c6824-5031-4e83-9321-438837d68925',
            name: 'Jenny',
            grade: '7',
            startYear: currentYear + 2,
            current: false,
            currentStudent: 'false',
            variant: '',
            site: 'default',
        },
        {
            id: '91a4bb1a-9937-44be-a9b5-ba88dd486a38',
            name: '',
            grade: '9',
            startYear: currentYear,
            current: true,
            currentStudent: 'true',
            variant: '',
            site: 'default',
        },
    ],
    suppressActions: false,
};
