"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetActiveStudentIndex = void 0;
const react_1 = require("react");
const immer_1 = require("immer");
const store_1 = require("../store");
const useSetActiveStudentIndex = () => {
    const setState = (0, store_1.useSetState)();
    const setActiveStudentIndex = (0, react_1.useCallback)((index) => {
        setState((obj) => (0, immer_1.produce)(obj, (draft) => {
            draft.activeStudentIndex = index;
        }));
    }, [setState]);
    return setActiveStudentIndex;
};
exports.useSetActiveStudentIndex = useSetActiveStudentIndex;
