"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Providers;
const jsx_runtime_1 = require("react/jsx-runtime");
const store_1 = require("./store");
const react_1 = require("@emotion/react");
const scopedCache_1 = require("./services/scopedCache");
const FeesableSnackbar_1 = require("./components/FeesableSnackbar/FeesableSnackbar");
function Providers({ children }) {
    return ((0, jsx_runtime_1.jsx)(react_1.CacheProvider, { value: scopedCache_1.scopedCache, children: (0, jsx_runtime_1.jsx)(store_1.SharedStateProvider, { children: (0, jsx_runtime_1.jsx)(FeesableSnackbar_1.SnackbarProvider, { children: children }) }) }));
}
