"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetCalcResponse = void 0;
const react_1 = require("react");
const immer_1 = require("immer");
const store_1 = require("../store");
const useSetCalcResponse = () => {
    const setState = (0, store_1.useSetState)();
    const setCalcResponse = (0, react_1.useCallback)((isLoading, data, submissionError = false) => {
        setState((obj) => (0, immer_1.produce)(obj, (draft) => {
            draft.calcResponse.isLoading = isLoading;
            draft.calcResponse.submissionError = submissionError;
            if (data !== undefined)
                draft.calcResponse.data = data;
        }));
    }, [setState]);
    return setCalcResponse;
};
exports.useSetCalcResponse = useSetCalcResponse;
